const dev = {
  pavilionAPI: "https://192.168.137.1:90/api/available",
};

const staging = {
  pavilionAPI: "https://game.pavilion.bluenode.paris/api/available",
};

const prod = {
  pavilionAPI: "https://game.pavilion.bluenode.paris/api/available",
};

export const config = {
  development: dev,
  staging,
  production: prod,
}[import.meta.env.MODE];

console.log(config);

if (config === undefined) {
  log.error(
    `Couldn't find configuration for environment: ${
      import.meta.env.VITE_ENVIRONMENT
    }`
  );
}

export const initLogs = () => {
  log.setLevel(config.logLevel, true);
};
