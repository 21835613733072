const Logo = () => {
  return (
    <div className="logo">
      <svg
        // width="82"
        // height="61"
        viewBox="0 0 82 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.7793 30.1191H23.8411L22.8065 27.5063L21.7793 30.1191Z"
          fill="black"
        />
        <path
          d="M22.8065 27.5063L21.7793 30.1191H23.8411L22.8065 27.5063Z"
          fill="black"
        />
        <path
          d="M55.6326 31.1856C54.0586 31.1856 54.0068 29.422 54.0068 29.2261C54.0068 29.0301 54.0512 27.2593 55.6326 27.2593C57.2141 27.2593 57.251 29.0229 57.251 29.2261C57.251 29.4293 57.2067 31.1856 55.6326 31.1856Z"
          fill="black"
        />
        <path
          d="M55.6324 27.9852C56.4526 27.9852 56.5117 28.936 56.5117 29.2263C56.5117 29.5166 56.4452 30.46 55.6324 30.46C54.8195 30.46 54.7456 29.5166 54.7456 29.2263C54.7456 28.936 54.8121 27.9852 55.6324 27.9852ZM55.6324 26.5337C54.0287 26.5337 53.2676 27.8836 53.2676 29.2263C53.2676 30.5689 54.0214 31.9116 55.6324 31.9116C57.2434 31.9116 57.9897 30.5617 57.9897 29.2263C57.9897 27.8909 57.236 26.5337 55.6324 26.5337Z"
          fill="black"
        />
        <path
          d="M14.7217 27.0923C15.4311 27.1286 15.4311 27.4044 15.4311 27.564C15.4311 27.7237 15.4311 27.985 14.7217 28.0213V27.0995V27.0923Z"
          fill="black"
        />
        <path
          d="M14.574 26.3594H13.9902V28.7399H14.574C15.3943 28.7399 16.1703 28.4641 16.1703 27.5569C16.1703 26.6497 15.3943 26.3594 14.574 26.3594Z"
          fill="black"
        />
        <path
          d="M1.8125 39.8365V1.45117H27.3299V8.07009H54.1774V14.6309H80.1899V39.8365L41.0012 59.374L1.8125 39.8365ZM50.2829 29.4437C50.3938 32.3104 52.7511 34.6111 55.6258 34.6111C58.5005 34.6111 60.88 32.2887 60.9761 29.4074V34.4369H64.538V30.6049L67.058 34.4369H70.28V24.0077H66.7181V27.8398L64.1981 24.0077H60.9761V29.0518C60.88 26.1632 58.5227 23.8481 55.6258 23.8481C52.729 23.8481 50.3938 26.1487 50.2829 29.0155V24.015H46.721V34.4442H50.2829V29.4509V29.4437ZM39.9075 34.4369H46.6175V31.1855H43.4694V24.0005H39.9075V34.4296V34.4369ZM35.9391 34.4369H39.501V24.0077H32.9092L31.1135 28.6091L29.3177 24.0077H25.3494L29.8055 34.4369H32.4289L35.9465 26.2068V34.4369H35.9391ZM24.7138 34.4369H28.564L24.2778 24.0077H21.3514L17.0653 34.4369H20.9154L21.5879 32.637H24.0413L24.7212 34.4369H24.7138ZM11.1681 34.4369H14.7301V31.1057H15.2474C17.5604 31.1057 19.1123 29.6541 19.1123 27.4986C19.1123 25.3431 17.5973 24.0077 15.2474 24.0077H11.1755V34.4369H11.1681Z"
          fill="black"
        />
        <path
          d="M26.5906 2.1771V8.79602H53.4381V15.3569H79.4506V39.3867L41.0009 58.554L2.55122 39.3867V2.1771H26.5906ZM51.0216 25.2345V23.2822H45.9817V30.4672H44.2007V23.2822H32.3991L32.037 24.2111L31.1132 26.5771L30.1895 24.2111L29.8274 23.2822H20.856L20.4791 24.1894L19.6589 26.1852C19.1268 24.4071 17.4862 23.2822 15.2397 23.2822H10.4289V35.1628H15.4688V31.8243C16.2447 31.7953 16.9394 31.6211 17.5453 31.3236L16.7842 33.167L15.9639 35.1628H21.4251L21.7798 34.2121L22.0975 33.3629H23.5164L23.8342 34.2121L24.1889 35.1628H32.909L33.2859 34.2701L35.1924 29.814V35.1628H51.0216V33.2178C52.1375 34.5169 53.7929 35.337 55.6255 35.337C57.4582 35.337 59.1136 34.5169 60.2368 33.2178V35.1628H65.2768V33.0654L66.2153 34.4951L66.6513 35.1628H71.0187V23.2822H65.9788V25.3796L65.0403 23.9499L64.6043 23.2822H60.2368V25.2417C59.121 23.9426 57.4656 23.1225 55.6255 23.1225C53.7855 23.1225 52.1375 23.9426 51.0216 25.2417M28.0686 0.725586H1.07324V40.2794L41.0009 60.187L80.9286 40.2794V13.9054H54.9161V7.3445H28.0686V0.725586ZM55.6329 33.8855C53.1056 33.8855 51.0216 31.8461 51.0216 29.2261C51.0216 26.6061 53.1056 24.5668 55.6329 24.5668C58.1603 24.5668 60.2442 26.6061 60.2442 29.2261C60.2442 31.8461 58.1603 33.8855 55.6329 33.8855ZM67.4568 30.3002V24.7264H69.5407V33.7041H67.4568L63.7988 28.1302V33.7041H61.7148V24.7264H63.7988L67.4568 30.3002ZM47.4597 33.7041V24.7264H49.5436V33.7041H47.4597ZM40.6388 33.7041V24.7264H42.7228V31.9114H45.8709V33.7113H40.6388V33.7041ZM36.6704 33.7041V24.7264H38.7544V33.7041H36.6704ZM31.1058 30.6268L33.4115 24.7264H35.7615L31.9261 33.7041H30.2855L26.4502 24.7264H28.8002L31.1058 30.6268ZM18.1513 33.7041L21.8463 24.7264H23.775L27.47 33.7041H25.2309L24.551 31.9042H21.0703L20.3979 33.7041H18.1587H18.1513ZM11.8995 33.7041V24.7264H15.2323C17.0207 24.7264 18.3582 25.6336 18.3582 27.4916C18.3582 29.3495 17.0207 30.3728 15.2323 30.3728H13.976V33.7041H11.8921H11.8995Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default Logo;
