import "./styles/index.scss";
import Homepage from "./page/Homepage";
import Legals from "./page/Legals";

import { Routes, Route } from "react-router-dom";
import Icon from "./components/Icon";

function App() {
  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Homepage />} />
        <Route path="/legals" element={<Legals />} />
      </Routes>

      <footer>
        <div className="c-socialmedia">
          <a href="https://twitter.com/BlueNodeParis"><Icon name="twitter"></Icon></a>
          <a href="https://www.instagram.com/bluenode.paris"><Icon name="instagram"></Icon></a>
        </div>

        <a href="/legals">LegalTerms</a>
      </footer>
    </div>
  );
}

export default App;
