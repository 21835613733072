import { useEffect, useState } from "react";
import { config } from "../config/constants";

import Logo from "../components/Logo";
const Homepage = () => {
  const url = config.pavilionAPI;

  const [availability, setAvailability] = useState(null);

  const getAvailability = async () => {
    const promise = await fetch(url, {
      method: "GET",
    })
      .then((res) => {
        const json = res.json();
        return json;
      })
      .then(function (data) {
        setAvailability(data.data);
        return data;
      })
      .catch((err) => setAvailability(false));
  };

  useEffect(() => {
    getAvailability();
  }, []);


  return (
    <div className="c-main c-homepage">
      <div className="c-cover hero">
        <video
          muted
          disableRemotePlayback
          loop
          playsInline
          autoPlay
          preload="true"
          className="hero-video"
        >
          <source src="./assets/mp4/video.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="s-about section">
        <Logo></Logo>
        <h1>Welcome to Pavilion</h1>

        <div>
          <p>
            <strong>
              An interactive experience in which you’re the adventurer. Build
              your world. Build Pavilion.
            </strong>
          </p>

          <p>Only available on a mobile device with a good network connection.</p>
        </div>

        {availability === true ? (
          <div className="button start">
            <a href="https://queue.pavilion.bluenode.paris/pavilion?target=https%3A%2F%2Fgame.pavilion.bluenode.paris%2F">
              <button>Enter</button>
            </a>
          </div>
        ) : (
          availability !== null && (
            <button className="disable">Not currently available</button>
          )
        )}
      </div>
    </div>
  );
};

export default Homepage;
