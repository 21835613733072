const Legals = () => {
  return (
    <div className="c-legals c-main">
      <div className="section">
        <h1>Legals Terms</h1>
        <h2>Website publisher</h2>
        <p>
          Blue Node is the website owner of www.bluenode.paris. You can contact
          the website creators via the following email address;
          contact@bluenode.paris.
        </p>
        <h2>Hosting conditions</h2>
        <p>
          Nos Sites et Applications sont hébergés sur les serveurs localisés en
          Europe de Amazon Web Services Inc, PO Box 81226, Seattle, WA
          981808-1226 – USA.
          https://aws.amazon.com/fr/compliance/eu-data-protection/.
        </p>
        <h2>Service provided description</h2>
        <p>
          Blue Node website is a portfolio of Blue Node’s various actions,
          achievements and projects.
        </p>
        <h2>Intellectual property</h2>
        <p>
          The source code, graphics, images, sounds, animations, texts, as well
          as the information and contents of www.bluenode.paris are protected by
          intellectual property copyrights. The total or partial reproduction of
          this website is prohibited without prior express written
          authorization.
        </p>
        <h2>Personal data managment</h2>
        <p>
          Personal data are protected by the law n° 78-87 of January 6, 1978,
          the law n° 2004- 801 of August 6, 2004, the article L. 226-13 of Penal
          Code and European Directive of October 24, 1995. The site is not
          declared to the CNIL because it does not collect personal information.
        </p>
        <h2>Applicable law and jurisdiction attribution</h2>
        <p>
          Any dispute in connection with the use of the site is subject to
          French law. The competent courts of Paris have exclusive jurisdiction.
        </p>
      </div>
    </div>
  );
};

export default Legals;
